import { ReactNode } from 'react';
import { Link, useLocation } from 'react-router-dom';

import { HouseCross, StoreBag } from '@images/icons';

type MenuItemProps = {
  to: string;
  newTab?: boolean;
  icon: ReactNode;
  showIndicator?: boolean;
  text: string;
};

const MenuItem = ({ to, text, icon, showIndicator, newTab }: MenuItemProps) => {
  const location = useLocation();
  const isActive = location.pathname === to;

  return (
    <Link
      to={to}
      aria-hidden={isActive ? 'true' : undefined}
      role={isActive ? 'presentation' : undefined}
      target={newTab ? '_blank' : undefined}
      className="flex flex-col items-center justify-center gap-0.5 text-primary no-underline md:flex-row md:gap-2"
    >
      <div className="relative flex h-9 items-center justify-center">
        {icon}
        {showIndicator && (
          <span className="absolute right-[-3px] top-0 box-border h-3.5 w-3.5 rounded-full border-2 border-white bg-green-600" />
        )}
      </div>
      <span className="text-center text-xs font-medium sm:text-sm">{text}</span>
    </Link>
  );
};

interface NavigationProps {
  hasCoadjuteId?: boolean;
}

export const Navigation = ({ hasCoadjuteId }: NavigationProps) => {
  return (
    <>
      <MenuItem
        to="/"
        text="Home Hub"
        icon={<HouseCross className="h-8 fill-primary md:h-[21px]" />}
      />
      <MenuItem
        to="https://store.home.cc/"
        text="Home Store"
        icon={<StoreBag className="h-[34px] fill-primary md:h-[21px]" />}
        newTab
      />
    </>
  );
};
