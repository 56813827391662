import { customCss } from './Homebox.styles';

export const BroadbandContent = () => {
  return (
    <>
      <style scoped>{customCss}</style>
      <div className="homebox-content" style={{ fontFamily: 'brandon-grotesque,sans-serif' }}>
        <span className="homebox-content_subtitle">
          Get your new home connected for move in day
        </span>
        <h1
          className="homebox-content_title"
          style={{ fontFamily: 'brandon-grotesque,sans-serif' }}
        >
          Set up your broadband in less than 5 minutes
        </h1>

        <div className="homebox-content_items">
          <div className="homebox-content_item">
            <div className="homebox-content_item_image-wrap">
              <img
                src="../images/energy-content-calendar.svg"
                alt="Calendar icon"
                width="72px"
                role="presentation"
              />
            </div>
            <div className="homebox-content_item_text">Guaranteed connection on move in day</div>
          </div>
          <div className="homebox-content_item">
            <div className="homebox-content_item_image-wrap">
              <img
                src="../images/broadband-content-search.svg"
                alt="Search icon"
                width="77px"
                role="presentation"
              />
            </div>
            <div className="homebox-content_item_text">Search accross 100s of deals</div>
          </div>
          <div className="homebox-content_item">
            <div className="homebox-content_item_image-wrap">
              <img
                src="../images/broadband-content-speed.svg"
                alt="Broadband icon"
                width="67px"
                role="presentation"
              />
            </div>
            <div className="homebox-content_item_text">Speed to suit your needs</div>
          </div>
          <div className="homebox-content_item">
            <div className="homebox-content_item_image-wrap">
              <img
                src="../images/energy-content-overpay.svg"
                alt="Overpay icon"
                width="67px"
                role="presentation"
              />
            </div>
            <div className="homebox-content_item_text">
              Avoid overpaying with price alerts of any better deals
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
