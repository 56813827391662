import axios from 'axios';
import { useState, useEffect, useMemo } from 'react';

import { environmentFor } from '@utils/environment';

export type HomeboxBroadbandProduct = {
  cheapestProduct: boolean;
  broadband: boolean;
  broadbandText: string;
  business: boolean;
  contractLength: number;
  credit: number;
  downloadLimit: number;
  downloadSpeed: number;
  effectiveFirstYearCost: number;
  effectiveFullContractCost: number;
  effectiveMonthlyCost: number;
  externalProductId: number;
  externalShortName: string;
  externalSupplierId: number;
  extrasText: string;
  fibre: boolean;
  freeCalls: number;
  fullContractCost: number;
  landLine: boolean;
  monthlyCost: number;
  monthlyCostText: string;
  offerExpiry: string;
  offerGraphicImage: any[]; // Assuming this is an array of any type, modify if you know the specific type
  offerLength: number;
  offerStart: string;
  offerText: string;
  originalMonthlyCost: number;
  originalMonthlyCostText: string;
  originalSetupFee: number;
  outlink: string;
  phoneNumber: string | null; // Phone number can be null
  phoneText: string;
  plusVat: number;
  postageCost: number;
  productName: string;
  rating: number | null; // Rating can be null
  setupFee: number;
  student: boolean;
  supplierImage: string;
  switchType: string;
  totalChannels: string;
  tv: boolean;
  tvBundles: any[]; // Assuming this is an array of any type, modify if you know the specific type
  tvChannels: any[]; // Assuming this is an array of any type, modify if you know the specific type
  tvIconText: string;
  tvPackageDescription: string;
  tvPackageImage: string;
  tvText: string;
  uploadSpeed: number;
};

type Data = {
  products: HomeboxBroadbandProduct[];
  recommendations: {
    cheapestProduct: HomeboxBroadbandProduct;
    fastestProduct: HomeboxBroadbandProduct;
  };
  totalCount: number;
  stats: {
    totalUnfiltered: number;
  };
};

type Options = {
  speed?: number;
  contractLength?: number;
};

export const useBroadbandComparison = (
  postcode: string,
  start: number,
  end: number,
  options?: Options
) => {
  const [data, setData] = useState<Data | null>(null);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);

  const ENVIRONMENT = environmentFor(window.location.href);
  const API_URL = ['dev', 'local'].includes(ENVIRONMENT)
    ? 'https://products-sandbox.homebox.io/api/v3/broadband/comparison'
    : 'https://products.homebox.io/api/v3/broadband/comparison';

  const memoizedSpeed = useMemo(() => options?.speed, [options?.speed]);
  const memoizedContractLength = useMemo(() => options?.contractLength, [options?.contractLength]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.post(API_URL, {
          start,
          end,
          partnerId: 'epCZ0ffkiYYod5sM',
          service: {
            preferences: {
              minServiceRating: 0.2,
              marketType: 'full',
              speed: memoizedSpeed || 0,
              minSpeed: 0,
              maxSpeed: 0,
              contractLength: memoizedContractLength || 0,
              minContractLength: 0,
              maxContractLength: 0,
              mediaTypes: 'broadband',
            },
          },
          user: {
            address: {
              postcode: postcode.replace(/\s/g, '').toUpperCase(),
            },
          },
        });

        setData(response.data);

        if (response.data.totalCount === 0 && !response.data.stats.totalUnfiltered) {
          setError(true);
        }
      } catch (err) {
        setError(true);
      } finally {
        setLoading(false);
      }
    };

    if (postcode) {
      fetchData();
    }
  }, [postcode, start, end, memoizedSpeed, memoizedContractLength, API_URL]);

  return { data, error, loading };
};
