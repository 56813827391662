export const customCss = `
  .homebox-content_subtitle {
    font-size: 20px !important;
    font-weight: 500 !important;
    margin: 0;
  }

  .homebox-content_title {
    font-size: 32px !important;
    font-weight: 500 !important;
    margin: 0;
  }
  
  .homebox-content_items {
    display: flex;
    padding: 30px 0;
    flex-wrap: wrap;
  }

  .homebox-content_item {
    flex: 50%;
    text-align: center;
  }

  .homebox-content_item_image-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 90px;
    width: 100%;
  }

  .homebox-content_item_text {
    max-width: 160px;
    font-weight: 500;
    text-align: center;
    font-size: 22px;
    margin: 0 auto;
  }

  @media (min-width: 900px) {
    .homebox-content_item {
      flex: 25%;
    }

    .homebox-content_subtitle {
      font-size: 22px !important;
    }

    .homebox-content_title {
      font-size: 40px !important;
    }
  }
`;
