import { twMerge } from 'tailwind-merge';

import { HOMERUNNER_CONTACT_DETAILS } from '@constants/homerunner';

import ConciergeImage from '@images/home-concierge.jpg';

const ContactDetail = ({ icon, name, link }: any) => {
  const Icon = icon;
  return (
    <div className="flex flex-col items-center gap-3 md:items-start">
      <span className="flex h-10 w-10 items-center justify-center">
        <Icon className="fill-white" />
      </span>
      <h3 className="pt-2 text-2xl font-medium">{name}</h3>
      <a
        href={link.href}
        rel="noreferrer"
        className="text-center text-lg font-light underline hover:no-underline"
      >
        {link.text}
      </a>
    </div>
  );
};

type ConciergeProps = {
  hideChat?: boolean;
};

export const Concierge = ({ hideChat }: ConciergeProps) => {
  // if hideChat is true, then don't show the chat by removing first item from the array
  const contactDetails = hideChat
    ? HOMERUNNER_CONTACT_DETAILS.slice(1)
    : HOMERUNNER_CONTACT_DETAILS;

  return (
    <div className="bg-home py-20 text-white xl:py-28" id="home-team">
      <section className="mx-auto flex max-w-7xl flex-col items-center gap-8 px-8 lg:px-10">
        <div className=" grid grid-cols-2  gap-14 xl:grid-cols-5">
          <div className="order-2 col-span-2 flex justify-center xl:col-span-2">
            <div className="w-full max-w-sm overflow-hidden rounded-2xl xl:max-w-none">
              <img src={ConciergeImage} alt="Home Concierge" className="inline-block" />
            </div>
          </div>
          <div className="order-1 col-span-2 flex flex-col items-center gap-6 xl:order-3 xl:col-span-3">
            <h2 className="text-center text-3xl font-semibold md:text-4xl lg:text-5xl">
              👋 Speak to your Home team
            </h2>
            <p className="text-center text-lg">
              We&apos;re on hand with everything you need for your home. <br />
              Monday to Friday 9am to 5.30pm.
            </p>
            <div
              className={twMerge(
                'mt-4 grid grid-cols-2 justify-center gap-8 md:grid-cols-4 md:flex-row',
                hideChat ? 'md:grid-cols-3' : 'md:grid-cols-4'
              )}
            >
              {contactDetails.map(contact => (
                <ContactDetail key={contact.name} {...contact} />
              ))}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
