import { useMediaQuery as reactResponsiveUseMediaQuery } from 'react-responsive';

export const useMediaQuery = () => {
  const IS_MOBILE = reactResponsiveUseMediaQuery({ query: '(max-width: 900px)' });
  const IS_TABLET = reactResponsiveUseMediaQuery({ query: '(min-width: 900px)' });
  const IS_DESKTOP = reactResponsiveUseMediaQuery({ query: '(min-width: 1200px)' });
  const IS_WIDESCREEN = reactResponsiveUseMediaQuery({ query: '(min-width: 1536px)' });

  return { IS_MOBILE, IS_TABLET, IS_DESKTOP, IS_WIDESCREEN };
};
