import { useCallback } from 'react';

import { ApiRoutes, TrackingEventType } from '@constants/index';

import axios from '@utils/customAxios';
// import * as Sentry from '@sentry/react';

type EventData = {
  eventType: TrackingEventType;
  data: Object;
};

export const useEventLogger = () => {
  const logEvent = useCallback(async (eventData: EventData) => {
    const data = {
      sessionId: localStorage.getItem('sessionId'),
      eventType: TrackingEventType[eventData.eventType],
      data: eventData.data,
      createdAt: new Date().toISOString(),
    };

    try {
      await axios.post(ApiRoutes.LOG, data, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
    } catch (error) {
      // Disabled for now until properly hooked up to avoid spamming errors
      // Sentry.captureException(error);
      console.error('Failed to log event', error);
    }
  }, []);

  return { logEvent };
};
