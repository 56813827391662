import { useMediaQuery } from 'react-responsive';
import { Link } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';

import { HouseCross } from '@images/icons';
import { ReactComponent as HomeLogoDesktop } from '@images/logo/desktop.svg';

type HomeLogoProps = {
  red?: boolean;
};

export const HomeLogo = ({ red }: HomeLogoProps) => {
  const showFullLogo = useMediaQuery({ query: '(min-width: 900px)' });

  const Component = showFullLogo ? HomeLogoDesktop : HouseCross;

  return (
    <Link to="/">
      <Component
        className={twMerge('h-[41px] cursor-pointer outline-none', red && 'fill-home')}
        role="img"
        aria-label="Home"
        tabIndex={0}
        data-testid="home-logo"
      />
    </Link>
  );
};
