export const ApiRoutes = {
  AUTH_CHECK: '/api/home/auth/check',
  HOME_INVITATION: '/api/home/invitation',
  PROFILE: '/api/home/me',
  CONFIRM_PASSWORD: '/api/home/confirm-password',
  MAYBE_FORGOT_PASSWORD: '/api/home/maybe-forgot-password',
  CONFIRM_FORGOT_PASSWORD: '/api/home/confirm-forgot-password',
  ONBOARD_USER: (id: string, email: string, leadId: string, sessionId: string) =>
    `/api/home/onboard?id=${id}&email=${email}&lead-id=${leadId}&sessionId=${sessionId}`,
  SIGNIN: '/api/home/auth/signin',
  SIGNUP: '/api/home/customers/signup/form',
  SIGNOUT: '/api/home/auth/signout',
  UPDATE_TASK: (propId: string, taskId: string) => `/api/home/properties/${propId}/tasks/${taskId}`,
  LOG: '/api/events',
  CHAT: '/api/home/hi/message',
  CHAT_HISTORY: '/api/home/hi/messages',
};

export enum WizardPaths {
  saleAgreed = 'sale-agreed',
  findAProperty = 'find-a-property',
  finance = 'finance',
  conveyancing = 'conveyancing',
  signContracts = 'sign-contracts',
  exchangeContracts = 'exchange-contracts',
  insurance = 'insurance',
  removals = 'removals',
  energy = 'energy',
  broadband = 'broadband',
  getReady = 'get-ready',
  complete = 'complete',
}

export enum WizardSteps {
  findProperty = 'FIND_PROPERTY',
  conveyancing = 'CONVEYANCING',
  contracts = 'CONTRACTS',
  insurance = 'INSURANCE',
  exchange = 'EXCHANGE',
  removals = 'REMOVALS',
  getMoveReady = 'MOVE_READY',
  complete = 'COMPLETE',
}

export const ProductLinks = {
  BUYER_INSURANCE: 'https://home.rhinohomeprotect.com/quote/',
  SELLER_INSURANCE: 'https://home.rhinohomeprotect.com/quote/',
  CONVEYANCING: '/services/conveyancing',
  STORE: {
    MAIN: 'https://store.home.cc/',
    SECURITY: 'https://store.home.cc/collections/home-security',
    PACKING_SUPPLIES: 'https://store.home.cc/collections/packing-supplies',
    PRODUCTS: {
      MOVING_PACK_3_BED:
        'https://store.home.cc/collections/packing-supplies/products/2-3-bedroom-removal-pack-40-boxes',
    },
  },
  HOME_INSURANCE:
    'https://homecc.quotezone.co.uk/home/index.php?id=5624248ac079e49fdd6736ce310c25e8',
  MORTGAGE: '',
  REMOVALS_ANYVAN: 'https://www.anyvan.com/partners/home',
  REMOVALS_ENTERPRISE: 'https://partners.rentalcar.com/home/',
  TENANTS_INSURANCE: 'https://quotes.paragonsecure.com/sales/home/',
  BUNDLES: 'https://app.homebox.co.uk/home/setup-account',
  SERVICES: 'https://www.home.cc/services',
};

export const Routes = {
  TERMS: '/terms',
  PRIVACY: '/privacy',
  SIGNIN: '/signin',
  FORGOT_PASSWORD: '/forgot-password',
  CONFIRM_FORGOT_PASSWORD: '/confirm-forgot-password',
  UPDATE_PASSWORD: '/update-password',
  INVITATIONS: '/invitations',
  CONFIRMATION: '/confirmation',
  SERVICES_CONVEYANCING: '/services/conveyancing',
  SERVICES_ENERGY: '/services/energy',
  SERVICES_BROADBAND: '/services/broadband',
  HOME: '/',
  CHAT: '/chat',
  SIGNUP: '/signup',
  HOME_INTELLIGENCE: '/home-intelligence',
};

export const BlogLinks = {
  CONVEYANCING: '',
  MORTGAGE_CALCULATOR: '',
  MORTGAGE_PROCESS: '',
  INSURANCE: '',
  EXCHANGE: '',
  COMPLETE: '',
  TENANTS_INSURANCE: '',
  REMOVALS: '',
};

export const GET_ADDRESS_API_KEY = 'JWLb1LMajUmU0Yhf_T7V-A35320';

export enum TrackingEventType {
  PAGE_VIEW_EVENT,
  SIGNUP_FORM_EVENT,
  EMAIL_SENT_EVENT,
}
