import axios from 'axios';
import { useState, useEffect, useMemo } from 'react';

import { environmentFor } from '@utils/environment';

export type HomeboxEnergyProduct = {
  contractLength: number;
  contractType: number;
  estimatedCost: number;
  estimatedSavings: number;
  exitFee: number;
  features: string[];
  groups: {
    economy7: boolean;
    isPaperless: boolean;
    meteringType: string;
    paymentMethod: string;
    rateTypes: string;
    rates: {
      discounts: any[];
      rateType: string;
      standingCharge: number;
      tiers: {
        unitPrice: number;
        tierType: string;
      }[];
      type: string;
    }[];
  }[];
  isRenewable: boolean;
  logo: string;
  productName: number;
  supplier: {
    descriptionIntro: string;
    features: string[];
    logo: string;
    machineName: string;
    name: string;
    renewablesOnly: boolean;
    serviceRating: number;
    terms: string;
    _id: string;
  };
  supplierDescriptionIntro: string;
  supplierId: string;
  supplierName: number;
  supplierServiceRating: number;
  terms: string;
  _id: string;
};

type Data = {
  products: HomeboxEnergyProduct[];
  recommendations: {
    cheapestProduct: HomeboxEnergyProduct;
    greenestProduct: HomeboxEnergyProduct;
  };
  totalCount: number;
  totalReturned: number;
};

type Options = {
  groupType: 'dualFuel' | 'electricityOnly' | 'gasOnly';
  usagePreset: 'low' | 'medium' | 'high';
};

export const useEnergyComparison = (
  postcode: string,
  start: number,
  end: number,
  options: Options
) => {
  const [data, setData] = useState<Data | null>(null);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);

  const ENVIRONMENT = environmentFor(window.location.href);
  const API_URL = ['dev', 'local'].includes(ENVIRONMENT)
    ? 'https://products-sandbox.homebox.io/api/v3/energy/comparison'
    : 'https://products.homebox.io/api/v3/energy/comparison';

  const memoizedGroupType = useMemo(() => options.groupType, [options.groupType]);
  const memoizedUsagePreset = useMemo(() => options.usagePreset, [options.usagePreset]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.post(API_URL, {
          start,
          end,
          partnerId: 'epCZ0ffkiYYod5sM',
          service: {
            preferences: {
              minServiceRating: 0.2,
              groupType: memoizedGroupType,
              paymentMethod: 'mdd',
              renewablesOnly: false,
              marketType: 'pro',
            },
            productDetails: {
              paymentMethod: 'mdd',
              productId: '5dceec8254dfec77a14f254a',
              groupType: memoizedGroupType,
            },
            usageEstimates: [
              {
                preset: memoizedUsagePreset,
              },
            ],
          },
          user: {
            address: {
              postcode: postcode.replace(/\s/g, '').toUpperCase(),
            },
          },
        });

        setData(response.data);

        if (response.data.totalCount === 0 && !response.data.stats.totalUnfiltered) {
          setError(true);
        }
      } catch (err) {
        setError(true);
      } finally {
        setLoading(false);
      }
    };

    if (postcode) {
      fetchData();
    }
  }, [postcode, start, end, memoizedGroupType, memoizedUsagePreset, API_URL]);

  return { data, error, loading };
};
