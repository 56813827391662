import { createAsyncThunk } from '@reduxjs/toolkit';

import { ApiRoutes } from '@constants/index';

import axios from '@utils/customAxios';

export const fetchMyProfile = createAsyncThunk('me/fetchMyProfile', (_, thunkAPI) =>
  axios
    .get(ApiRoutes.PROFILE, {
      withCredentials: true,
    })
    .then(response => response.data)
    .catch(error => thunkAPI.rejectWithValue(JSON.stringify(error.response)))
);
