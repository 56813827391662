import { Chat, Phone } from '@images/icons';

export const HomerunnerContact = () => {
  return (
    <div
      className="box-border flex w-full max-w-sm flex-col gap-5 rounded-2xl bg-white p-6 shadow-md"
      aria-labelledby="homerunners-heading"
    >
      <h4 id="homerunners-heading" className="text-2xl font-medium">
        HomeRunners
      </h4>
      <p>
        <strong>Need a hand?</strong> Our team of Home Runners are here to help.
      </p>
      <span
        className="flex cursor-pointer items-center gap-5 underline hover:no-underline"
        onClick={() => window.LiveChatWidget.call('maximize')}
        role="button"
        aria-label="Start a live chat"
        tabIndex={0}
        onKeyDown={e => {
          if (e.key === 'Enter') window.LiveChatWidget.call('maximize');
        }}
      >
        <Chat className="h-8 w-8 fill-primary" data-testid="chat-icon" aria-hidden />
        <span>Start a live chat</span>
      </span>
      <a
        className="flex items-center gap-5 underline hover:no-underline"
        href="tel:03300433828"
        aria-label="Call Home Runners at 03300 433 828"
      >
        <Phone className="h-8 w-8 fill-primary" data-testid="phone-icon" aria-hidden />
        <span>03300 433 828</span>
      </a>
    </div>
  );
};
