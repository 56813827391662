import React from 'react';

type HeadingProps = {
  children: React.ReactNode;
  icon?: React.ReactNode;
  level?: 1 | 2 | 3 | 4 | 5 | 6;
  customStyles?: string;
};

/**
 * Default to H1 sizes for now
 */
export const Heading = ({ children, icon, level = 1, customStyles }: HeadingProps) => {
  const HeadingTag = `h${level}` as keyof JSX.IntrinsicElements;

  return (
    <HeadingTag
      className={`flex flex-col items-center gap-4 text-center text-4xl font-medium md:flex-row md:text-5xl ${customStyles}`}
    >
      {icon && <span aria-hidden>{icon}</span>}
      {children}
    </HeadingTag>
  );
};
