import React, { forwardRef } from 'react';
import { FieldError } from 'react-hook-form';

type InputProps = React.InputHTMLAttributes<HTMLInputElement> & {
  label: string;
  id: string;
  error?: FieldError | undefined;
};

/**
 * FloatingLabelInput component
 * Built to work with react-hook-form, this is the "old" style of input that has a floating label
 * @param label - The label for the input
 * @param id - The id for the input
 * @param error - The error object for this input from react-hook-form formState
 * @param rest - All other input props, set by destructuring the register function from react-hook-form or field from Controller
 */
export const FloatingLabelInput = forwardRef<HTMLInputElement, InputProps>(
  ({ label, id, error, ...rest }, ref) => {
    return (
      <div className="flex w-full flex-col gap-1">
        <div className="group relative w-full">
          <input
            {...rest}
            ref={ref}
            id={id}
            className={`input peer w-full bg-transparent px-3 py-4 outline-none`}
            placeholder=" "
            aria-label={label}
            aria-invalid={!!error}
          />

          <label
            htmlFor={id}
            className={`pointer-events-none absolute left-[9px] top-px -translate-y-1/2 transform px-1 text-sm text-gray-600 transition-all duration-300 group-focus-within:!top-px group-focus-within:!text-sm group-focus-within:!text-primary peer-placeholder-shown:top-1/2 peer-placeholder-shown:text-base`}
          >
            {label}
          </label>

          {/* For floating label */}
          <fieldset
            aria-hidden
            className={`pointer-events-none invisible absolute inset-0 mt-[-9px] rounded border border-gray-300 transition-all duration-150 group-focus-within:border-2 group-focus-within:border-primary group-hover:border-gray-600 group-focus:border-primary peer-placeholder-shown:visible`}
          >
            <legend
              className={`invisible ml-2 max-w-[0.01px] whitespace-nowrap px-0 text-sm transition-all duration-300 group-focus-within:max-w-full group-focus-within:px-1`}
            >
              {label}
            </legend>
          </fieldset>

          {/* Used for floating label when not focussed */}
          <fieldset
            aria-hidden
            className={`pointer-events-none visible absolute inset-0 mt-[-9px] rounded border border-gray-300 transition-all duration-150 group-focus-within:border-2 group-focus-within:border-primary group-hover:border-gray-600 group-focus:border-primary peer-placeholder-shown:invisible`}
          >
            <legend className={`invisible ml-2 max-w-full whitespace-nowrap px-1 text-sm`}>
              {label}
            </legend>
          </fieldset>
        </div>
        {!!error && <span className="mt-1 text-sm text-red-500">{error.message}</span>}
      </div>
    );
  }
);
