import { customCss } from './Homebox.styles';

export const EnergyContent = () => {
  return (
    <>
      <style scoped>{customCss}</style>
      <div className="homebox-content" style={{ fontFamily: 'brandon-grotesque,sans-serif' }}>
        <span className="homebox-content_subtitle">Find and stay on the best energy deals</span>
        <h1
          className="homebox-content_title"
          style={{ fontFamily: 'brandon-grotesque,sans-serif' }}
        >
          Set up your energy in less than 10 minutes
        </h1>

        <div className="homebox-content_items">
          <div className="homebox-content_item">
            <div className="homebox-content_item_image-wrap">
              <img
                src="../images/energy-content-save.svg"
                alt="Save icon"
                width="72px"
                role="presentation"
              />
            </div>
            <div className="homebox-content_item_text">Save up to £430 a year</div>
          </div>
          <div className="homebox-content_item">
            <div className="homebox-content_item_image-wrap">
              <img
                src="../images/energy-content-calendar.svg"
                alt="Calendar icon"
                width="77px"
                role="presentation"
              />
            </div>
            <div className="homebox-content_item_text">In place for move in day</div>
          </div>
          <div className="homebox-content_item">
            <div className="homebox-content_item_image-wrap">
              <img
                src="../images/energy-content-chat.svg"
                alt="Chat icon"
                width="67px"
                role="presentation"
              />
            </div>
            <div className="homebox-content_item_text">We'll inform your current supplier</div>
          </div>
          <div className="homebox-content_item">
            <div className="homebox-content_item_image-wrap">
              <img
                src="../images/energy-content-overpay.svg"
                alt="Overpay icon"
                width="67px"
                role="presentation"
              />
            </div>
            <div className="homebox-content_item_text">Avoid overpaying with autoswitch</div>
          </div>
        </div>
      </div>
    </>
  );
};
