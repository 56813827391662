import { Link } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';

import { ReactComponent as HomeLogoDesktop } from '@images/logo/desktop.svg';

type FooterProps = {
  isMoving: boolean;
  hideNavigation?: boolean;
};

export const Footer = ({ isMoving, hideNavigation }: FooterProps) => {
  return (
    <footer className="flex w-full flex-col justify-center bg-black pb-20 text-secondary md:pb-0">
      <div className="mx-auto w-full max-w-[1440px] p-12">
        <div className="grid grid-cols-1 gap-5 sm:grid-cols-2 md:grid-cols-4">
          <div className="col-span-1 sm:col-span-2 md:col-span-1">
            <HomeLogoDesktop className="w-[152px] fill-secondary" aria-label="Home Logo" />
          </div>
          {!hideNavigation && (
            <div className="col-span-1 py-4">
              <h4 className="pb-4 text-2xl font-medium">Quick links</h4>
              <ul className="flex flex-col gap-2 text-base font-medium">
                <li>
                  <Link to="/">Home Hub</Link>
                </li>
                <li>
                  <Link to="/your-team">{isMoving ? 'Move Team' : 'Home Team'}</Link>
                </li>
                <li>
                  <Link to="/messages">Messages</Link>
                </li>
                <li>
                  <Link to="https://store.home.cc/" target="_blank">
                    Home Store
                  </Link>
                </li>
              </ul>
            </div>
          )}
          {isMoving && !hideNavigation && (
            <div className="col-span-1 py-4">
              <h4 className="pb-4 text-2xl font-medium">Home setup</h4>
              <ul className="flex flex-col gap-2 text-base font-medium">
                <li>
                  <Link to="/wizard/removals">Removals</Link>
                </li>
                <li>
                  <Link to="/wizard/insurance">Insurance</Link>
                </li>
                <li>
                  <Link to="/wizard/energy">Utilities</Link>
                </li>
                <li>
                  <Link to="/wizard/broadband">Broadband</Link>
                </li>
                <li>
                  <Link to="/wizard/get-ready">Get move ready</Link>
                </li>
              </ul>
            </div>
          )}
        </div>
      </div>
      <hr
        className={twMerge(
          'h-[1px] border-none bg-secondary ',
          hideNavigation ? '' : 'mt-10 md:mt-28'
        )}
      />
      <div className="mx-auto w-full max-w-[1440px] p-12">
        <div className="grid grid-cols-1 md:grid-cols-2">
          <div className="col-span-1">
            <p className="text-base font-medium">© Copyright 2023 Home. All rights reserved. </p>
          </div>
          <div className="col-span-1">
            <div className="flex items-center justify-start gap-11 pt-12 text-base font-medium md:justify-end md:pt-0">
              <Link to="/terms">Terms &amp; Conditions</Link>
              <Link to="/privacy">Privacy Policy</Link>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};
