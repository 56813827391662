import { ReactNode } from 'react';
import { Link } from 'react-router-dom';

import { Spinner } from '@components/Spinner';

type ButtonProps = {
  children: ReactNode;
  disabled?: boolean;
  onClick?: () => void;
  isSubmitting?: boolean;
  customStyles?: string;
};

type LinkButtonProps = {
  href: string;
  target?: string;
} & ButtonProps;

export const Button = ({
  children,
  disabled,
  isSubmitting,
  onClick,
  customStyles,
}: ButtonProps) => {
  const disabledStyles = disabled ? 'opacity-50 cursor-not-allowed' : '';
  const submittingStyles = isSubmitting ? 'cursor-not-allowed' : '';
  return (
    <button
      className={`${customStyles} flex h-14 w-full max-w-96 items-center justify-center rounded-2xl bg-primary px-9 text-lg font-medium text-white shadow-md ${disabledStyles} ${submittingStyles}`}
      type="submit"
      disabled={disabled || isSubmitting}
      onClick={() => !!onClick && onClick()}
    >
      {isSubmitting ? <Spinner /> : children}
    </button>
  );
};

export const LinkButton = ({ href, target, ...buttonProps }: LinkButtonProps) => {
  return (
    <Link to={href} target={target}>
      <Button {...buttonProps} />
    </Link>
  );
};
