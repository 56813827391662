import React from 'react';
import { Link } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';

type NavLinkProps = {
  to: string;
  icon: React.ReactElement;
  label: string;
  external?: boolean;
  onClick?: () => void;
  isActive?: boolean;
};

export const NavLink = ({
  to,
  icon,
  label,
  external = false,
  onClick,
  isActive = false,
}: NavLinkProps) => {
  return (
    <div className="col-span-3 flex justify-center md:col-span-1">
      <Link
        to={to}
        target={external ? '_blank' : undefined}
        rel={external ? 'noopener noreferrer' : undefined}
        onClick={onClick}
        className={twMerge(
          'group relative flex flex-col items-center justify-center gap-1 px-1 py-3 sm:px-3 md:flex-row md:gap-3 md:py-5'
        )}
      >
        <div className="flex h-8 items-center justify-center">
          {React.cloneElement(icon, { className: twMerge(icon.props.className, 'fill-primary') })}
        </div>
        <span className="text-center text-xs font-medium sm:text-sm md:text-base">{label}</span>
        <div
          className={twMerge(
            'absolute bottom-0 left-1/2 h-1 w-full -translate-x-1/2 bg-home transition-all duration-300 ease-in-out',
            isActive ? 'w-full' : 'w-0'
          )}
        ></div>
      </Link>
    </div>
  );
};
